import { Box, SxProps, TextField } from "@mui/material";
import React from "react";

const inputSx: SxProps = {
    marginTop: '40px',
    width: '100%',
};

const CustomInput = (inputProps) => {
    return (
        <Box sx={inputSx}>
            <TextField fullWidth { ...inputProps } error={Boolean(inputProps.error)} helperText={inputProps.error ? inputProps.error : inputProps.helperText} />
        </Box>
    )
}

export default CustomInput;