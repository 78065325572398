import { Head, useForm } from '@inertiajs/react';
import { Box, Button, Divider, SxProps, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CustomInput from '@/Partials/CustomInput';
import React from 'react';
import AlertMessage from '@/Components/AlertMessage';
import WindowIcon from '@mui/icons-material/Window';
import useMediaQuery from '@mui/material/useMediaQuery';

const loginSx: SxProps = {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    margin: 4,
    alignItems: 'center',
};

const logoSx: SxProps = {
    width: 300,
};

const buttonSx: SxProps = {
    mt: 3,
    mb: 3,
    width: 1,
}

export default function Login() {
    const { data, setData, post, processing, errors } = useForm({
        email: '',
        password: '',
    });

    const matches = useMediaQuery((theme) => theme.breakpoints.up('md'));

    const handleSubmit = (e : MouseEvent) => {
        e.preventDefault();
        post('/login');
    };

    return (
        <>
            <Head>
                <title>Login</title>
            </Head>
            <Box sx={{
                display: 'flex',
                height: '100vh',
                justifyContent: 'center',
                }}>
                {matches ? <Box component="img" sx={{width: '20%'}} src="images/login-pattern-left.svg"></Box> : ''}
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexBasis: matches ? '80%' : '100%',
                }}>
                    <Box component="img" sx={logoSx} src="images/cwc-logo.svg" alt="logo"></Box>
                    <Box sx={{
                        display: 'flex',
                        width: '100%',
                        maxWidth: 600,
                        }}>
                        <Box component="form" sx={loginSx} onSubmit={handleSubmit} method="POST" action="/login">
                            <Typography>City Wide Event Portal</Typography>
                            <AlertMessage sx={{ mt: 5 }}/>
                            <CustomInput id="email" label="Email Address" type="email" required value={data.email} autoComplete='off' onChange={e => setData('email', e.target.value)} error={errors.email} />
                            <CustomInput id="password" label="Password" type="password" required value={data.password} autoComplete='off' onChange={e => setData('password', e.target.value)} error={errors.password} />
                            <LoadingButton sx={buttonSx} variant="contained" color="tertiary" type="submit" loading={processing} >Login</LoadingButton>
                            <Divider flexItem />
                            <Button startIcon={<WindowIcon />} sx={buttonSx} variant="outlined" color="tertiary" href={route('adfslogin')} >Login with your work account</Button>
                        </Box>
                    </Box>
                </Box>
                {matches ? <Box component="img" sx={{width: '20%'}} src="images/login-pattern-right.svg"></Box> : ''}
            </Box>
        </>
    );
}
